<template>
  <div>
    <div
      v-for="(config, configIndex) in instanceConfig"
      :key="configIndex"
    >
      <v-text-field
        outlined
        dense
        class="mb-4"
        clearable
        :label="`${$t('product.data.spec')}${(configIndex+1)}`"
        :messages="$t('product.data.spec.help')"
        v-model="instanceConfig[configIndex].name"
      ></v-text-field>

      <v-combobox
        outlined
        dense
        v-model="instanceConfig[configIndex].attributes"
        multiple
        :label="$t('product.data.spec.attributes')"
        :messages="`${$t('product.data.spec.attributes.help')} , ${$t('combobox.help')}`"
        small-chips
        clearable
      >
        <template v-slot:selection="{ item, selected, select, index }">
          <v-chip color="primary lighten-1" small>
            <span class="mr-4">{{item}}</span>
            <span
              @click="removeItem(configIndex, index)"
              class="combobox-item-remove"
            >
            &times;
          </span>
          </v-chip>
        </template>
      </v-combobox>

      <v-divider class="mt-4 mb-6"></v-divider>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
    data: {
      type: Array,
      default: () => null,
    },
  },
  data: () => ({
    instanceConfig: [
      { name: null, attributes: [] },
      { name: null, attributes: [] },
    ],
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(!this.data) return
      if(this.data.length == 0) return
      if(this.data.length == 1) {
        this.$set(this.instanceConfig, 0, window.eagleLodash.cloneDeep(this.data)[0])
        return
      }
      this.$set(this.instanceConfig, 0, window.eagleLodash.cloneDeep(this.data)[0])
      this.$set(this.instanceConfig, 1, window.eagleLodash.cloneDeep(this.data)[1])
    },
    removeItem(configIndex, index) {
      this.$delete(this.instanceConfig[configIndex].attributes, index)
    },
  },
  computed: {
    syncValue() {
      let data = []
      const instanceConfig = window.eagleLodash.cloneDeep(this.instanceConfig)
      for(const config of instanceConfig) {
        if(this.$helper.textEmpty(config.name)) continue
        if(!Array.isArray(config.attributes)) continue
        const everyAttributesEmpty = config.attributes.every(attr => this.$helper.textEmpty(attr))
        if(everyAttributesEmpty) continue
        if(config.attributes.length == 0) continue
        config.attributes = config.attributes.filter(attr => !this.$helper.textEmpty(attr))
        data.push(config)
      }
      return data
    },
  },
  watch: {
    instanceConfig: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.syncValue))
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>